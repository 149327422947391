/** @jsx jsx */
import { jsx, Container } from "theme-ui"

export const SectionOne = ({ sectionOne }) => {
  const { sectionOneTitle, sectionOneContent } = sectionOne
  return (
    <section className="sectionOne" sx={{ textAlign: "center" }}>
      <Container sx={{ maxWidth: 1150 }}>
        <h2 sx={{ fontSize: [30, 50], color: "green", mb: [30, 70] }}>
          {sectionOneTitle}
        </h2>

        <div
          dangerouslySetInnerHTML={{ __html: sectionOneContent }}
          sx={{
            p: {
              fontSize: [20, 30],
            },
            iframe: {
              height: [200, 360],
              width: "100%",
            },
          }}
        />
      </Container>
    </section>
  )
}
